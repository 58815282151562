<script>
export default {
  name: "spinner"
}
</script>

<template>
  <div style="width: 20px; height: 20px" class="text-white spinner-border text-primary avatar-sm" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</template>

<style scoped>

</style>