<script>
import { helpers, required} from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import API from "@/plugins/axios-method";
import VueSelect from 'vue-select'
import store from "@/state/store";
import {formatedUser} from "@/helpers/functions";
import Modal from "@/components/modal.vue";

export default {
  name: 'town-form-modal',
  components: {VueSelect, Modal},
  props: {
    title: {
      type: String,
      default: "Selectionnez votre ville"
    },
  },
  setup(){
    return { v$: useVuelidate() }
  },
  data(){
    return {
      visible: true,
      loadingRegions: true,
      loadingTowns: false,
      selectedTown: null,
      selectedRegion: null,
      regions: [],
      towns: [],
      isSubmitting: false,
      isSubmitted: false,
    }
  },
  mounted() {
    this.getRegions()
  },
  methods: {
    formatedUser,
    formSubmit(){
      this.createTown()
    },
    getRegions(){
      API.get('/regions')
      .then(response => {
        response.data["hydra:member"].map(r => {
          this.regions.push({
            label: r.name,
            value: r['@id'],
            id: r.uniqId
          })
        })
      })
          .finally(() => this.loadingRegions = false)
    },
    changeTowns(val){
      this.towns = []
      this.loadingTowns = true
      API.get(`/regions/${val.id}/cities`)
          .then(response => {
            response.data["hydra:member"].map(t => {
              this.towns.push({
                label: t.name,
                value: t['@id'],
              })
            })
          })
          .finally(() => this.loadingTowns = false)
    },
    createTown(){
      if (this.validateForm()) return
      this.isSubmitted = true

      API.patch( `/user/${this.$store.getters['auth/userId']}/town_update`, {
        city: this.selectedTown.value,
      })
          .then(() => {
            API.get('/users/get/logged_user')
            .then(res => {
              const user = this.formatedUser(res.data, ['onlinePaymentRequests', 'accounts']);
              store.commit('auth/SET_CURRENT_USER', user)
              this.createSuccess()
            })
          })
          .catch(() => {
            this.isSubmitted = false
          })
    },
    createSuccess(){
      this.isSubmitting = false
      this.isSubmitted = false
      window.location.reload()
    },
    clearForm(){
      this.v$.$reset()
      this.name = null
    },
    validateForm(){
      this.v$.$validate()
      return !!this.v$.$error
    },
  },
  validations: {
    selectedTown: {
      required: helpers.withMessage("La ville est requise", required),
    },
    selectedRegion: {
      required: helpers.withMessage("La region est requise", required)
    }
  }
}
</script>

<template>
  <Modal :title="title" v-model="visible" no-close-on-esc size="lg">
    <div class="">
      <form @submit.prevent="formSubmit">
        <div class="mb-3">
          <label for="telephone" class="form-label">Region</label>
          <vue-select :loading="loadingRegions" @update:modelValue="changeTowns" :class="{'is-invalid': v$.selectedRegion.$errors.length }" :options="regions" v-model="selectedRegion"/>
          <div class="invalid-feedback" v-for="error in v$.selectedRegion.$errors" :key="error.$uid">
            <span>{{ error.$message }}</span>
          </div>
        </div>
        <div class="mb-3">
          <label for="telephone" class="form-label">Ville</label>
          <vue-select :loading="loadingTowns" :class="{'is-invalid': v$.selectedTown.$errors.length }" :options="towns" v-model="selectedTown"/>
          <div class="invalid-feedback" v-for="error in v$.selectedTown.$errors" :key="error.$uid">
            <span>{{ error.$message }}</span>
          </div>
        </div>
        <div class="mt-4">
          <button :disabled="isSubmitted" class="btn btn-success w-100" type="submit">
            <div v-if="isSubmitted" style="width: 20px; height: 20px" class="text-white spinner-border text-primary avatar-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <span v-if="!isSubmitted">Enregistrer</span>
          </button>
        </div>
      </form>
    </div>
  </Modal>

</template>

<style scoped>
.btn-close{
  display: none;
}
</style>