<script>
import {getRelativeTime, getTruncateString} from "@/helpers/functions";
export default {
  name: "notification-item",
  props: {
    notification: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      read: false,
      fake: "https://via.placeholder.com/50",
      fakeImg: "@/assets/images/users/avatar-8.jpg"
    }
  },
  methods: {
    getTruncateString,
    getRelativeTime,
  },
  computed: {
    getNotificationToLink(){
      if (this.notification.fundingRequestIdentifier) {
        return "/admin/funding-requests/" + this.notification.fundingRequestIdentifier
      } else {
        return "/admin/notifications/" + this.notification.id
      }
    }
  }
}
</script>

<template>
  <div class="text-reset notification-item d-block dropdown-item" :class="{'read': !notification.status}">
    <router-link :to="getNotificationToLink" class="d-flex">
      <img :src="fake" class="me-3 rounded-circle avatar-xs"
           alt="user-pic" />
      <div class="flex-1">
        <div class="stretched-link">
          <h6 class="mt-0 mb-1 fs-13 fw-semibold">
            {{ notification.senderFullName }}
          </h6>
        </div>
        <div class="fs-13 text-muted">
          <p class="mb-1">
            {{ getTruncateString(notification.content, 35) }}
          </p>
        </div>
        <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
          <span><i class="mdi mdi-clock-outline"></i> {{ getRelativeTime(notification.createdAt) }}</span>
        </p>
      </div>
<!--      <div class="px-2 fs-15">-->
<!--        <input class="form-check-input" type="checkbox" />-->
<!--      </div>-->
    </router-link>
  </div>
</template>

<style scoped>
  .read{
    background-color: #d5e2ff;
  }
</style>