<script>
  export default {
    name: 'modal',
    props: {
      value: {},
      title: {
        type: String,
        default: ''
      },
      modalSize: {
        type: String,
        default: 'lg'
      }
    },
    data(){
      return {
        visible: false
      }
    },
    watch: {
      value(newValue) {
        this.visible = newValue
      },
      visible(newValue){
        this.visible = newValue
      }
    },
    methods: {
      onModalClose(){
        this.visible = false
        this.$emit('closeModal', 'user-form')
      }
    }
  }
</script>

<template>
  <!-- Grids in modals -->
  <b-modal v-model="visible"  :title="title" size="lg" centered hide-footer @hide="onModalClose()" no-close-on-backdrop>
    <slot/>
  </b-modal>
</template>

<style>
  .modal-content{
    z-index: 1500;
  }
</style>