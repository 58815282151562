<script>
export default {
  name: "no-notifications"
}
</script>

<template>
  <div class="w-25 w-sm-50 pt-3 mx-auto">
    <img src="@/assets/images/svg/bell.svg" class="img-fluid" alt="user-pic" />
  </div>
  <div class="text-center pb-5 mt-2">
    <h6 class="fs-18 fw-semibold lh-base">
      Vous n'avez aucune notification
    </h6>
  </div>
</template>

<style scoped>

</style>