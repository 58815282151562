<script>
import {SimpleBar} from "simplebar-vue3";
import NotificationItem from "@/components/notification/notification-item.vue";
import Spinner from "@/components/spinner.vue";

export default {
  name: "notification-list",
  components: {Spinner, NotificationItem, SimpleBar},
  props: {
    notifications: {
      type: Array,
      required: true,
      default(){
        return []
      }
    },
    next: {
      type: String,
      required: false,
      default: null
    },
    loadingData:{
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      showDataLoader: false,
      showLoadMoreButton: false
    }
  },
  methods:{
    loadMore() {
      this.showDataLoader = true
      this.$emit('next')
    },
  },
  watch: {
    next(newValue){
      this.showLoadMoreButton = !!newValue
    },
    loadingData(newValue) {
      this.showDataLoader = newValue
    }
  },
}
</script>

<template>
  <SimpleBar data-simplebar style="max-height: 300px" class="pe-2">
    <notification-item v-for="(n, index) in notifications" :key="index" :notification="n"/>
    <div class="my-3 text-center" v-if="showLoadMoreButton">
      <button :disabled="showDataLoader" @click="loadMore" type="button" class="btn btn-primary">
        <span v-if="!showDataLoader">Chargez plus</span>
        <spinner v-if="showDataLoader"/>
      </button>
    </div>
  </SimpleBar>
</template>

<style scoped>

</style>