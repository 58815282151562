import Axios from "@/plugins/axios"

const headerConfig = {
    headers: {
        'Content-Type': 'application/ld+json',
        'Accept':  'application/ld+json',
    }
}

// Used to submit file
const headerConfig2 = {
    headers: {
        'Content-Type': 'application/merge-patch+json',
        'Accept': 'application/ld+json'
        // 'Accept-Patch': 'application/merge-patch+json',
    }
}

const headers =  {
    'Content-Type': 'application/ld+json',
    'Accept':  'application/ld+json',
    //'Accept-Encoding': '*',
}

const headersFormData =  {
    'Content-Type': 'multipart/form-data',
    'accept': 'application/ld+json',
}

const API = {
    name: 'API',
    get(url, params) {
        return Axios.get(url, {headers, params})
    },
    post(url, data) {
        return Axios.post(url, data, headerConfig)
    },
    statusPatch(url, data){
        return Axios.patch(url, data, headerConfig)
    },
    patch(url, data) {
        return Axios.patch(url, data, headerConfig2)
    },

    delete(url, data) {
        return Axios.delete(url, data)
    },

    postFormData(url, data) {
        return Axios.post(url, data, headersFormData)
    },

    put(url, id, data) {
        return Axios.put(`${url}/${id}`, data, headerConfig)
    },

    find(url, param) {
        return Axios.get(url, param, headerConfig)
    },
}

export default API