<script>
import NoNotifications from "@/components/notification/no-notifications.vue";
import API from "@/plugins/axios-method";
import NotificationList from "@/components/notification/notification-list.vue";

export default {
  name: "notification-dropdown",
  components: {NotificationList, NoNotifications},
  data() {
    return {
      notifications: [],
      unReadCount: 0,
      url: null,
      page: 1,
      next: null,
      loadingData: false
    }
  },
  mounted() {
    this.initUrl()
    this.getNotifications()
  },
  methods: {
    getNotifications(){
      API.get(this.url)
          .then(response => {
            this.setPagination(response)
            response.data['hydra:member'].map(notification => {
              if (notification.status === 0) this.unReadCount++
              this.notifications.push(notification)
            })
          })
          .finally(() => {
            this.loadingData = false
          })
    },
    initUrl(){
      this.url = "/admin/notifications?type=message&page=" + this.page
    },
    setPagination(response){
      if (response.data["hydra:view"]["hydra:next"]) {
        this.next = response.data["hydra:view"]["hydra:next"]
      } else {
        this.next = null
      }
    },
    nextPage(){
      this.loadingData = true
      this.setCurrentPage(this.next)
      this.getNotifications()
    },

    setCurrentPage(url){
      const currentPage = Number(url.split('page=')[1])
      if (currentPage !== this.page) this.page = currentPage
      this.url = url.split('api/')[1]
      this.showDataLoader = true
    }
  }
}
</script>

<template>
  <div class="dropdown topbar-head-dropdown ms-1 header-item">
    <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
      <i class="bx bx-bell fs-22"></i>
      <span v-if="unReadCount > 0" class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{{ unReadCount }}<span class="visually-hidden">unread messages</span></span>
    </button>
    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
         aria-labelledby="page-header-notifications-dropdown">
      <div class="dropdown-head bg-primary bg-pattern rounded-top">
        <div class="p-3">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="m-0 fs-16 fw-semibold text-white">
                Notifications
              </h6>
            </div>
            <div class="col-auto dropdown-tabs">
              <span class="badge badge-soft-light fs-13"> {{ unReadCount }} Nouveau{{unReadCount > 0 ? 'x' : ''}}</span>
            </div>
          </div>
        </div>

        <div class="px-2 pt-2">
          <ul class="nav nav-tabs dropdown-tabs nav-tabs-custom" data-dropdown-tabs="true"
              id="notificationItemsTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" data-bs-toggle="tab" href="#all-noti-tab" role="tab"
                 aria-selected="false" @click.capture.stop>
                Message{{unReadCount > 0 ? 's' : ''}} ({{ unReadCount }})
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="tab-content" id="notificationItemsTabContent">

        <div class="tab-pane fade py-2 ps-2 show active" id="all-noti-tab" role="tabpanel">
          <notification-list :loading-data="loadingData" :next="next" @next="nextPage" :notifications="notifications"/>
          <no-notifications v-if="notifications.length === 0"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>